<section class="category-section">
  <ul class="panel-group" id="accordion">
    <li class="panel active" *ngIf="id=='category1'">
      <div class="panel-heading">
          <h3 class="panel-title removeAttr" data-toggle="collapse" data-parent="#accordion" aria-expanded='true' id="1" (click)="toggleCollapse(1)">
              <span>{{title}}</span>
          </h3>
      </div>
      <div id="collapse{{1}}" class="panel-collapse collapse show">
        <div>
          <ul>
            <li><div><a href="assets/A.01.Frequently.Asked.Questions.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/A.01.Frequently.Asked.Questions.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/A.01.Frequently.Asked.Questions.pdf" target="_blank">A.01 Frequently Asked Questions</a></span></li>
            <li><div><a href="assets/A.02.Glossary.of.Frequently.Used.Terms.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/A.02.Glossary.of.Frequently.Used.Terms.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/A.02.Glossary.of.Frequently.Used.Terms.pdf" target="_blank">A.02 Glossary of Frequently Used Terms</a></span></li>
            <li><div><a href="assets/A.03.Group.Number.Access.Code.Information.Sheet.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/A.03.Group.Number.Access.Code.Information.Sheet.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/A.03.Group.Number.Access.Code.Information.Sheet.pdf" target="_blank">A.03 Group Number Access Code Information Sheet</a></span></li>
            <li><div><a href="assets/A.04.Glic.Pharmacy.Networks.2022.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/A.04.Glic.Pharmacy.Networks.2022.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/A.04.Glic.Pharmacy.Networks.2022.pdf" target="_blank">A.04 Glic Pharmacy Networks 2024</a></span></li>
        </ul>
      </div>      
    </div>        
    </li>
    <li class="panel active" *ngIf="id=='category2'">
      <div class="panel-heading">
          <h3 class="panel-title removeAttr" data-toggle="collapse" data-parent="#accordion" aria-expanded='true' id="2" (click)="toggleCollapse(2)">
              <span>{{title}}</span>
          </h3>
      </div>
      <div id="collapse{{2}}" class="panel-collapse collapse show">
        <div>
          <ul>
            <li><div><a href="assets/B.01.Marketing.Approval.Process.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.01.Marketing.Approval.Process.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.01.Marketing.Approval.Process.pdf" target="_blank">B.01 Marketing Approval Process</a></span></li>
            <li><div><a href="assets/B.02.Social.Media.Best.Practices.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.02.Social.Media.Best.Practices.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.02.Social.Media.Best.Practices.pdf" target="_blank">B.02 Social Media Best Practices</a></span></li>
            <li><div><a href="assets/B.03a.Template.Card.File.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.03a.Template.Card.File.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.03a.Template.Card.File.pdf" target="_blank">B.03a Template Card File</a></span></li>
            <li><div><a href="assets/B.03b.Template.Card.File.agent.info.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.03b.Template.Card.File.agent.info.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.03b.Template.Card.File.agent.info.pdf" target="_blank">B.03b Template Card File- agent info </a></span></li>
            <li><div><a href="assets/B.03c.Example.Instagram.Post.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.03c.Example.Instagram.Post.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.03c.Example.Instagram.Post.pdf" target="_blank">B.03c Example Instagram Post </a></span></li>
            <li><div><a href="assets/B.03d.Example.Facebook.post.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.03d.Example.Facebook.post.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.03d.Example.Facebook.post.pdf" target="_blank">B.03d Example Facebook post</a></span></li>
            <li><div><a href="assets/B.03e.Social.Media.Marketing.Posts.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.03e.Social.Media.Marketing.Posts.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.03e.Social.Media.Marketing.Posts.pdf" target="_blank">B.03e Social Media Marketing Posts</a></span></li>
            <li><div><a href="assets/B.03f.GlicRx.Mailer.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.03f.GlicRx.Mailer.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.03f.GlicRx.Mailer.pdf" target="_blank">B.03f GlicRx Mailer</a></span></li>
            <li><div><a href="assets/B.03g.display.poster.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.03g.display.poster.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.03g.display.poster.pdf" target="_blank">B.03g Display Poster</a></span></li>
            <li><div><a href="assets/B.03h_B.03i.Pharmacy.Logos.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.03h_B.03i.Pharmacy.Logos.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.03h_B.03i.Pharmacy.Logos.pdf" target="_blank">B.03h _B.03i Pharmacy Logos</a></span></li>
            <li><div><a href="assets/B.03j.GlicRx.promotional.email.to.customers.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/B.03j.GlicRx.promotional.email.to.customers.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/B.03j.GlicRx.promotional.email.to.customers.pdf" target="_blank">B.03j GlicRx promotional email to customers</a></span></li>
        </ul>
        <span class="material">How do I get marketing approval? Email your materials to <a href="mailto:submitforapproval@glichealth.com">submitforapproval@glichealth.com</a></span>
      </div>      
    </div>        
    </li>
    <li class="panel active" *ngIf="id=='category3'">
      <div class="panel-heading">
          <h3 class="panel-title removeAttr" data-toggle="collapse" data-parent="#accordion" aria-expanded='true' id="3" (click)="toggleCollapse(3)">
              <span>{{title}}</span>
          </h3>
      </div>
      <div id="collapse{{3}}" class="panel-collapse collapse show">
        <div>
          <ul>
            <li><div><a href="assets/C.01.Editing.Your.Glic.Card.File.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/C.01.Editing.Your.Glic.Card.File.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/C.01.Editing.Your.Glic.Card.File.pdf" target="_blank">C.01 Editing Your Glic Card File</a></span></li>
            <li><div><a href="assets/C.02.How.to.Print.Cards.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/C.02.How.to.Print.Cards.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/C.02.How.to.Print.Cards.pdf" target="_blank">C.02 How to Print Cards</a></span></li>
          </ul>
      </div>      
    </div>        
    </li>
    <li class="panel active" *ngIf="id=='category4'">
      <div class="panel-heading">
          <h3 class="panel-title removeAttr" data-toggle="collapse" data-parent="#accordion" aria-expanded='true' id="4" (click)="toggleCollapse(4)">
              <span>{{title}}</span>
          </h3>
      </div>
      <div id="collapse{{4}}" class="panel-collapse collapse show">
        <div>
          <ul>
            <li><div><a href="assets/D.01.Addressing.Client.Questions.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/D.01.Addressing.Client.Questions.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/D.01.Addressing.Client.Questions.pdf" target="_blank">D.01 Addressing Client Questions</a></span></li>
            <li><div><a href="assets/D.02.Customer.Information-one.pager.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/D.02.Customer.Information-one.pager.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/D.02.Customer.Information-one.pager.pdf" target="_blank">D.02 Customer Information-one pager</a></span></li>
            <li><div><a href="assets/D.03.Client.Card.One.Pager.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/D.03.Client.Card.One.Pager.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/D.03.Client.Card.One.Pager.pdf" target="_blank">D03 Client Card One Pager</a></span></li>
          </ul>          
      </div>      
    </div>        
    </li>
    <li class="panel active" *ngIf="id=='category5'">
      <div class="panel-heading">
          <h3 class="panel-title removeAttr" data-toggle="collapse" data-parent="#accordion" aria-expanded='true' id="5" (click)="toggleCollapse(5)">
              <span>{{title}}</span>
          </h3>
      </div>
      <div id="collapse{{5}}" class="panel-collapse collapse show">
        <div>
          <ul>
            <li><div><a href="assets/E.01.GlicRx.Top.50.Pricing.Sheet.CVS.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/E.01.GlicRx.Top.50.Pricing.Sheet.CVS.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/E.01.GlicRx.Top.50.Pricing.Sheet.CVS.pdf" target="_blank">E.01 GlicRx Top 50 Pricing Sheet CVS</a></span></li>
            <li><div><a href="assets/E.02.GlicRx.Top.50.Pricing.Sheet.Walgreens.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/E.02.GlicRx.Top.50.Pricing.Sheet.Walgreens.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/E.02.GlicRx.Top.50.Pricing.Sheet.Walgreens.pdf" target="_blank">E.02 GlicRx Top 50 Pricing Sheet Walgreens</a></span></li>
            <li><div><a href="assets/E.03.GlicRx.Top.10.Pricing.CVS.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/E.03.GlicRx.Top.10.Pricing.CVS.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/E.03.GlicRx.Top.10.Pricing.CVS.pdf" target="_blank">E.03 GlicRx Top 10 Pricing CVS</a></span></li>
            <li><div><a href="assets/E.04.GlicRx.Top.10.Pricing.Walgreens.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/E.04.GlicRx.Top.10.Pricing.Walgreens.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/E.04.GlicRx.Top.10.Pricing.Walgreens.pdf" target="_blank">E.04 GlicRx Top 10 Pricing Walgreens</a></span></li>
            <li><div><a href="assets/E.05.GlicRx.Top.10.Pet.Prescriptions.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/E.05.GlicRx.Top.10.Pet.Prescriptions.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/E.05.GlicRx.Top.10.Pet.Prescriptions.pdf" target="_blank">E.05 GlicRx Top 10 Pet Prescriptions</a></span></li>
            <li><div><a href="assets/E.06.Senior.Medication.Pricing.Sheet.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/E.06.Senior.Medication.Pricing.Sheet.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/E.06.Senior.Medication.Pricing.Sheet.pdf" target="_blank">E.06 Senior Medication Pricing Sheet</a></span></li>
        </ul>
      </div>      
    </div>        
    </li>
    <li class="panel active" *ngIf="id=='category6'">
      <div class="panel-heading">
          <h3 class="panel-title removeAttr" data-toggle="collapse" data-parent="#accordion" aria-expanded='true' id="6" (click)="toggleCollapse(6)">
              <span>{{title}}</span>
          </h3>
      </div>
      <div id="collapse{{6}}" class="panel-collapse collapse show">
        <div>
          <ul>
            <li><div><a href="assets/F.01.How.Does.the.Enrollment.Center.Work.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/F.01.How.Does.the.Enrollment.Center.Work.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/F.01.How.Does.the.Enrollment.Center.Work.pdf" target="_blank">F.01 How Does the Enrollment Center Work</a></span></li>
            <li><div><a href="assets/F.02.Enrollment.Center.One.Pager.pdf" target="_blank" class="view"><img src="../assets/images/pdf.png" alt="" />View</a><a class="download-icon" href="assets/F.02.Enrollment.Center.One.Pager.pdf" download><img src="../assets/images/download-white.png" alt="" />Download</a></div><span><a href="assets/F.02.Enrollment.Center.One.Pager.pdf" target="_blank">F.02 Enrollment Center One Pager</a></span></li>
        </ul>
      </div>      
    </div>        
    </li>
  </ul>  
</section>